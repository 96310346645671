@keyframes squashstretchTalk {
	0% {
        transform-origin: bottom;
        transform: scaleY(0.95); 
	}
	
	100% {
        transform-origin: bottom;
        transform: scaleY(1.0);
	}
}

@keyframes squashstretchMute {
	0% {
        transform-origin: bottom;
        transform: scaleY(0.95); 
	}
	
	100% {
        transform-origin: bottom;
        transform: scaleY(1.0);
	}
}

@keyframes blinker {
    0% { opacity: 0; }
    100% { opacity: 1; filter: brightness(0%) }
}

@keyframes damage-shake {
    0% { transform: translateY(0) }
    25% { transform: translateY(3px) }
    50% { transform: translateY(-3px) }
    75% { transform: translateY(3px) }
    100% { transform: translateY(0) }
}

@keyframes killed-fade {
    0% { filter: brightness(100%); }
    100% { filter: brightness(15%); }
}

.player-img {
    animation: squashstretchTalk 0.2s ease-in-out 0s forwards;
}

.player-img-mute {
    animation: squashstretchMute 0.2s ease-in-out 0s forwards;
}

.player-img-dmg {
    animation: squashstretchTalk 0.2s ease-in-out 0s forwards, blinker 0.2s linear 2, damage-shake 0.1s linear 6;
}

.player-killed {
    animation: killed-fade 0.6s ease-in-out 0s forwards, damage-shake 0.1s linear 6 ;
}