.effect {
    position: absolute;
}

@keyframes effect-gradient-in {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        opacity: 0.7;
        transform: scale(1);
    }
}

@keyframes effect-gradient {
    0% {
        transform: skew(0deg, 0deg) scaleY(1.0) scaleX(1.05);
    }

    25% {
        transform: skew(5deg, 3deg) scaleY(1.1) scaleX(1.05);
    }

    50% {
        transform: skew(0deg, 0deg) scaleY(0.95) scaleX(0.95);
    }

    75% {
        transform: skew(-6deg, -5deg) scaleY(1.1) scaleX(1.05);
    }

    100% {
        transform: skew(0deg, 0deg) scaleY(1.0) scaleX(1.05);
    }
}

.effect-gradient-green {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgb(255,0,0,0) 0%, rgba(3, 95, 40, 0.6) 30%);
    top: 0;
    left: 0;
    z-index: 1;
    animation: effect-gradient-in 0.4s ease-in-out forwards, 1.0s effect-gradient 0.4s step-end infinite;
}

.effect-gradient-red {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgb(255,0,0,0) 0%, rgba(128, 0, 0, 0.6) 30%);
    top: 0;
    left: 0;
    z-index: 1;
    animation: effect-gradient-in 0.4s ease-in-out forwards, 2.0s effect-gradient 2.0s ease-in-out infinite;
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    80% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1.0);
    }
}

@keyframes pop-out {
    0% {
        opacity: 1;
        transform: scale(1.0);
    }

    100% {
        opacity: 0;
        transform: scale(0.2);
    }
}

.pop-in-anim {
    opacity: 0;
    z-index: 2;
    animation: pop-in 1.0s ease-in-out forwards, 1.0s pop-out 1.0s ease-in-out forwards;
}

@keyframes drip {
    0% {
        opacity: 100%;
        padding-top: 0px;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
        padding-top: 100px;
    }
}

.drip-anim {
    z-index: 2;
    animation: drip 1.0s ease-in forwards;
}

@keyframes pulse {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.pulse-anim {
    z-index: 2;
    animation: pulse 2.0s ease-in-out infinite;
}

.sweat-anim {
    z-index: 2;
    animation: pulse 0.5s ease-in-out reverse infinite;
}

@keyframes halo-in {
    0% {
        opacity: 0;
        transform: scale(4);
    }

    100% {
        opacity: 0.6;
        transform: scale(1.65);
    }
}

@keyframes halo-bounce {
    0% {
        opacity: 0.6;
        transform: scale(1.65);
    }

    100% {
        opacity: 1;
        transform: scale(1.8);
    }
}

@keyframes halo-pulse {
    0% {
        transform: scale(1.8);
    }

    50% {
        transform: scale(1.65);
    }

    100% {
        transform: scale(1.8);
    }
}

.halo-anim {
    z-index: 2;
    transform-origin: center;
    animation: halo-in 0.4s ease-in-out forwards, 0.2s halo-bounce 0.4s ease-in-out forwards, 2.0s halo-pulse 0.8s ease-in-out infinite;
}

.halo-anim-reverse {
    z-index: 2;
    transform-origin: center;
    animation: halo-in 0.4s ease-in-out reverse;
}

@keyframes deathblow-pop {
	0% {
        transform: scale(2.5);
	}

    60% {
        transform: scale(1.3);
    }
	
	100% {
        transform: scale(1.5);
	}
}

@keyframes death-fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes death-fade-in {
    0% { opacity: 0; /* transform: rotate(0deg); */ }
    100% { opacity: 1; }
}

.deathblow-anim {
    z-index: 2;
    transform-origin: center;
    padding-right: 300px;
    animation: deathblow-pop 0.2s ease-in-out forwards, 1.5s death-fade-out 0.3s ease-in-out forwards;
}

@keyframes bloodsplatter-pop {
	0% {
        width: 85%;
        top: 50px;
        left: 70px;
	}

    50% {
        width: 87%;
        top: 50px;
        left: 70px;
    }
	
	100% {
        width: 85%;
        top: 50px;
        left: 70px;
	}
}

.bloodsplatter-anim {
    z-index: 2;
    animation: bloodsplatter-pop 1.5s ease-in-out forwards, death-fade-in 0.8s ease-in-out forwards, 1.5s death-fade-out 0.8s ease-in-out forwards;
}

@keyframes status {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-80px);
    }
}

.status-anim {
    z-index: 2;
    font-family: "King Arthur Legend";
    animation: status 2.5s ease-in-out forwards;
}

@font-face {
    font-family: 'King Arthur Legend';
    src: local('King Arthur Legend'), url('./fonts/King\ Arthur\ Legend.ttf');
}